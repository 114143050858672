import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { EMPTY, Observable, catchError, map, of } from 'rxjs';
import { UserModel } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { TelegramService } from '../services/telegram.service';

export const userResolver: ResolveFn<UserModel> = (route, state) => {
  const authService = inject(AuthService);
  const telegramService = inject(TelegramService);

  telegramService.init();
  const tgData = telegramService.tg.initData;

  return <Observable<UserModel>>authService.auth(tgData).pipe(
    map((data) => data),
    catchError(() => {
      return of(EMPTY);
    })
  );
};
