import { Routes } from '@angular/router';
import { userResolver } from './core/resolvers/user.resolver';

export const routes: Routes = [
  {
    path: '',
    resolve: {
      user: userResolver,
    },
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
];
